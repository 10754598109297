import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PlanningType from '../../types/Planning';
import Item from '../new/Item';
import ItemSub from '../new/Item/Sub';

const PlanningRoutes: FC<{ planning: PlanningType }> = ({ planning }) => {
  const { t } = useTranslation();
  const { routes } = planning;

  if (!routes || 0 === routes.length) {
    return <div>Aucune route</div>;
  }

  return (
    <div className="flex flex-col space-y-6">
      {routes.map(route => {
        const {
          cost,
          distance,
          duration,
          error,
          vehicle,
          volume,
          weight,
          steps,
        } = route;

        const CustomInfos = error ? (
          <div>{error}</div>
        ) : (
          <div className="flex flex-col">
            <span>{vehicle ? vehicle.name : ''}</span>
            <div className="flex space-x-2">
              <span className="italic text-light-900">{`${
                Math.round((distance || 0) / 100) / 10
              }km`}</span>
              <span className="italic text-light-900">{`${Math.round(
                (duration || 0) / 60,
              )}'`}</span>
              <span className="italic text-light-900">{`${
                (cost || 0) / 100
              }€`}</span>
              <span className="italic text-light-900">{`${
                volume || 0
              }m3`}</span>
              <span className="italic text-light-900">{`${
                weight || 0
              }kg`}</span>
            </div>
          </div>
        );

        // let status: string;

        // let count = expeditors.length;
        // // expeditors: toutes les usagers qui ont des activités
        // if (1 === expeditors.length) {
        //   if (vehicle) {
        //     if (vehicle.userId === expeditors[0].id) {
        //       status = 'routes.status.user-own-vehicle';
        //     } else {
        //       status = 'routes.status.user-with-carrier-vehicle';
        //     }
        //   } else {
        //     status = 'routes.status.user-without-vehicle';
        //   }
        // } else if (vehicle) {
        //   if (expeditors.every(expeditor => expeditor.id !== vehicle.userId)) {
        //     status = 'routes.status.users-with-carrier-vehicle';
        //   } else {
        //     status = 'routes.status.users-with-user-vehicle';
        //     count -= 1;
        //   }
        // } else {
        //   status = 'routes.status.users-without-vehicle';
        // }
        if (!steps) {
          return null;
        }

        const departure = steps[0];
        const arrival = steps[steps.length - 1];
        const etapes = steps.slice(1, steps.length - 1);

        return (
          <Item
            key={route.id}
            id={route.id}
            showPathname={`/admin/plannings/read/${planning.id}/routes/`}
            subTitle={CustomInfos}
            title="Route"
          >
            {etapes && etapes.length > 0 && (
              <div className="flex flex-col space-y-2">
                <ItemSub
                  subTitle={etapes.map(step => step.place.name).join(', ')}
                  title={`${departure.place.name} → ${arrival.place.name} (${t(
                    'routes.steps.nb',
                    {
                      count: etapes.length,
                    },
                  )})`}
                />
              </div>
            )}
          </Item>
        );
      })}
    </div>
  );
};

export default PlanningRoutes;
