import objectHash from 'object-hash';
import React, { FC } from 'react';

import PlanningType from '../../types/Planning';
import VehicleType from '../../types/Vehicle';
import VehicleItem from '../item/Vehicle';
import List from '../new/Body/List';

const PlanningVehicles: FC<{ planning: PlanningType }> = ({ planning }) => {
  const { places, vehicles } = planning;

  return vehicles && vehicles.length > 0 ? (
    <List>
      {vehicles.map((vehicle: VehicleType) => (
        <VehicleItem
          key={objectHash({ vehicleId: vehicle.id })}
          places={places}
          vehicle={vehicle}
        />
      ))}
    </List>
  ) : (
    <div>Aucun véhicule</div>
  );
};

export default PlanningVehicles;
