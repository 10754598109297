import compact from 'lodash/compact';
import objectHash from 'object-hash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PlanningType from '../../types/Planning';
import Item from '../new/Item';
import ItemSub from '../new/Item/Sub';

const PlanningActivities: FC<{ planning: PlanningType }> = ({ planning }) => {
  const { t } = useTranslation();

  const { activities } = planning;

  if (!activities || 0 === activities.length) {
    return <div>aucune activité</div>;
  }

  return (
    <div className="flex flex-col space-y-6">
      {activities.map(activity => {
        const { place, user, deliveries } = activity;

        return (
          <Item
            key={objectHash({ activityId: activity.id })}
            id={activity.id}
            subTitle={user && user.name}
            title={place.name}
          >
            {deliveries && deliveries.length > 0 && (
              <div className="flex flex-col space-y-2">
                {deliveries.map(delivery => {
                  const { containers } = delivery;

                  const names: string[] = [];
                  let nbBio = 0;
                  let nbPacked = 0;
                  let nbPallet = 0;
                  let nbRefrigerated = 0;
                  let nbStackable = 0;
                  let quantity = 0;
                  let maxDepth = 0;
                  let maxHeight = 0;
                  let maxTemperature = 1000;
                  let maxWidth = 0;
                  let totalVolume = 0;
                  let totalWeight = 0;
                  containers?.forEach(container => {
                    names.push(container.name);
                    if (container.hasPallet) {
                      nbPallet += container.quantity;
                    }
                    if (container.isBio) {
                      nbBio += container.quantity;
                    }
                    if (container.isPacked) {
                      nbPacked += container.quantity;
                    }
                    if (container.isRefrigerated) {
                      nbRefrigerated += container.quantity;
                    }
                    if (container.isStackable) {
                      nbStackable += container.quantity;
                    }

                    quantity += container.quantity;
                    maxHeight = Math.max(maxHeight, container.height);
                    maxWidth = Math.max(maxWidth, container.width);
                    maxDepth = Math.max(maxDepth, container.depth);
                    const volume =
                      (container.height * container.width * container.depth) /
                      1000000;
                    totalVolume += container.quantity * volume;
                    totalWeight += container.quantity * container.weight;
                    if (container.temperature) {
                      maxTemperature = Math.min(
                        maxTemperature,
                        container.temperature,
                      );
                    }
                  });

                  const infos: string[] = compact([
                    quantity &&
                      t('planning.activities.item.quantity', {
                        count: quantity,
                      }),
                    nbBio &&
                      t('planning.activities.item.nb-bio', {
                        count: nbBio,
                      }),
                    nbPacked &&
                      t('planning.activities.item.nb-packed', {
                        count: nbPacked,
                      }),
                    nbPallet &&
                      t('planning.activities.item.nb-pallet', {
                        count: nbPallet,
                      }),
                    nbRefrigerated &&
                      t('planning.activities.item.nb-refrigerated', {
                        count: nbRefrigerated,
                      }),
                    nbStackable &&
                      t('planning.activities.item.nb-stackable', {
                        count: nbStackable,
                      }),
                    t('planning.activities.item.total-volume', {
                      count: Math.round(totalVolume * 1000) / 1000,
                    }),
                    t('planning.activities.item.total-weight', {
                      count: totalWeight,
                    }),
                  ]) as string[];

                  return (
                    <ItemSub
                      key={objectHash({
                        activityId: activity.id,
                        deliveryId: delivery.id,
                        userId: activity.userId,
                      })}
                      subTitle={
                        <div>
                          <div>{names.join(', ')}</div>
                          <div className="text-light-900">
                            {infos.join(', ')}
                          </div>
                        </div>
                      }
                      title={delivery.place.name}
                    />
                  );
                })}
              </div>
            )}
          </Item>
        );
      })}
    </div>
  );
};

export default PlanningActivities;
