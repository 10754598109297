import dayjs from 'dayjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/base/Button';
import CircularProgress from '../../../../components/base/CircularProgress';
import GridSplit from '../../../../components/base/GridSplit';
import Tabs from '../../../../components/base/Tabs';
import IconFileExport from '../../../../components/icons/FileExport';
import PlanningActivities from '../../../../components/Planning/Activities';
import PlanningRoutes from '../../../../components/Planning/Routes';
import PlanningVehicles from '../../../../components/Planning/Vehicles';
import AsideBody from '../../../../components/Space/Aside/Body';
import planningsRetrieve from '../../../../objects/plannings/queries/retrieve';
import PlanningType from '../../../../types/Planning';
import requiredAuth from '../../../../utils/requireAuth';

const PlanningPage: FC<PageProps> = ({ params: { id } }) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(planningsRetrieve, {
    variables: {
      id,
    },
  });

  const planning: PlanningType =
    data && (data.planningsRetrieve as PlanningType);
  if (!planning) {
    return <CircularProgress fullPage />;
  }

  return (
    <GridSplit
      aside={{
        body: planning?.places && <AsideBody places={planning.places} />,
        className: 'sticky top-0 self-start',
        width: '50%',
      }}
      header={{
        actions: (
          <div className="flex space-x-4 items-center">
            <Button
              iconLeft={IconFileExport}
              size="small"
              status="secondary"
              target="_blank"
              title={t('planning.actions.export')}
              to={`/export/planning/data-in/${id}`}
            />
          </div>
        ),
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: (
          <div className="flex flex-col">
            <span className="font-bold text-xl ">Planning</span>
            <span>
              {planning?.updatedAt
                ? `Mis à jour ${dayjs(planning.updatedAt).format(
                    'dddd D MMMM YYYY à hh:mm',
                  )}`
                : ''}
            </span>
          </div>
        ),
      }}
    >
      {loading && <CircularProgress />}
      <Tabs
        className="mx-6"
        values={[
          {
            component: <PlanningRoutes planning={planning} />,
            name: 'planning.tabs.routes',
          },
          //   {
          //     component: <ScenarioUsers scenario={planning} />,
          //     name: 'scenario.tabs.users',
          //   },
          {
            component: <PlanningActivities planning={planning} />,
            name: 'planning.tabs.activities',
          },
          {
            component: <PlanningVehicles planning={planning} />,
            name: 'planning.tabs.vehicles',
          },
        ]}
      />
    </GridSplit>
  );
};

export default requiredAuth(PlanningPage, {
  admin: true,
  layout: true,
});
